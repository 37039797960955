import React, { FC } from "react"

import { InfoItem } from "./InfoItem"
import styled from "styled-components"

interface InfoBoxProps {}

const Conatiner = styled.div`
  width: 100%;
  box-shadow: 1px 4px 9px -6px #000 inset, 1px -4px 9px -6px #000 inset;
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: 20px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  > :not(:first-child) {
    margin-top: 20px;
  }
`

export const InfoBox: FC<InfoBoxProps> = () => {
  return (
    <Conatiner>
      <p>
        Możliwość spłaty w <strong>Nieoprocetowanych</strong> ratach
      </p>
      <Wrapper>
        <InfoItem rata="Rata I" desc="1000zł" label="Przy zapisie na kurs." />
        <InfoItem
          rata="Rata II"
          desc="1000zł"
          label="Po ukończeniu teorii, przed praktyką."
        />
        <InfoItem
          rata="Rata III"
          desc="pozostałość"
          label="Do 10 godz. jazd."
        />
        {/* <InfoItem rata="Rata IV" desc="pozostałość" label="do 20 godz. jazd." /> */}
      </Wrapper>
    </Conatiner>
  )
}

export default InfoBox
